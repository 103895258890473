import IMask from 'imask';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const solutionsButtons = document.querySelectorAll('.solutions__menu__button');
const phoneInput = document.getElementById('phone');

function solutionsButtonClickSwiperNavigate(e) {
  swiper.slideTo(e.currentTarget.dataset.solutionsIndex - 1);
}

const swiper = new Swiper('.swiper', {
  pagination: {
    el: '.swiper-pagination',
  },
});

swiper.on('slideChange', function () {
  const slideIndex = swiper.activeIndex;

  for (let solutionsButton of solutionsButtons) {
    if (slideIndex === solutionsButton.dataset.solutionsIndex - 1) {
      solutionsButton.classList.add('solutions__menu__button--active');
    } else {
      solutionsButton.classList.remove('solutions__menu__button--active');
    }
  }
});

for (let solutionsButton of solutionsButtons) {
  solutionsButton.addEventListener('click', solutionsButtonClickSwiperNavigate)
}

IMask(phoneInput, { mask: '(00) 00000-0000' });